<template>
  <div class="collection">
    <div class="collection__image image">
      <a href="" class="image__item">
        <img src="@/assets/images/vol_2/Ula_52483_DONE-min.jpg" alt="">
      </a>
      <div class="image__label">(Lorem ipsum)</div>
    </div>
    <div class="collection__image image">
      <a href="" class="image__item image__item_small">
        <img src="@/assets/images/vol_2/Ula_52499_DONE-min.jpg" alt="">
      </a>
      <div class="image__label">(Lorem ipsum)</div>
    </div>
    <div class="collection__image image">
      <a href="" class="image__item">
        <img src="@/assets/images/vol_2/Ula_52514_DONE-min.jpg" alt="">
      </a>
      <div class="image__label">(Lorem ipsum)</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CollectionBlock",
}
</script>
